html,
body {
  height: 100%;
  width: 100%;
  font-family: 'Inter', sans-serif;
}

.appWrapper {
  text-align: center, darkText;
}
.root {
  height: 100%;
  width: 100%;
}

/* Default Colors */
.rokketmedOrange {
  color: '#FF5B0F';
}
.darkText {
  color: '#13223E';
}
.white {
  color: '#FFFFFF';
}
.pinkAccent {
  color: '#F63468';
}
.offWhiteBackground {
  color: '#F9FCFD';
}
.graySubtitle {
  color: '#6B7280';
}
.lightGreyBackground {
  color: '#F7F7F7';
}
